import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import { useConfig } from '../../../context/ConfigsContext';
import { useGetWallet } from '../../../hooks/useWallets';
import { AddDischarge } from '../../../data/ManageWallet';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { BuscarPromo } from '../transferencia/components/BuscarCodigo';
import { Input, InputDisabled } from '../../../components/inputs/Input';
import { InputAUD } from '../../../components/inputs/InputCoin';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { ModalValidar } from '../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { SetReceiver } from '../../../components/forms/SetReceiver';
import { useGetBusiness, useGetReceivers } from '../../../hooks/useGetInfo';
import { SetFeeV2 } from '../../../components/forms/SetFee';
import SinFondos from './components/SinFondos';
import {AgregaUnContacto, Restringed} from '../../error/components/Restringed';
import SVG from 'react-inlinesvg'
import animation from '../../../img/animation.svg';
import ImgCheck from '../../../img/icons/tick-square.svg';

const Transferir = () => {

  const {infoUser} = useAuth();
  const {OpenModal, setProcesando, FormatAud, sideFix, Capitalize, dateEmail} = useConfig();
  const Navigate = useNavigate();
  const [uidUsuario, setUidUsuario] = useState();
  const [receivers] = useGetReceivers(uidUsuario);
  const [destinos, setDestinos] = useState(false);
  const pais = 'AUD';

  const [fondos, setFondos] = useState();
  const [nameWallet, setNameWallet] = useState();
  const [email, setEmail] = useState('')
  const [empresa, setEmpresa] = useState(false);
  const [walletID, setWalletID] = useState();
  const [business] = useGetBusiness();
  const [empresaID, setEmpresaId] = useState('')
  const [walletB, setWalletB] = useState('');
  const [walletN, setWalletN] = useState('');
  const [receptor, setReceptor] = useState();
  const [restringed, setRestringed] = useState(false)
  const receiver = useRef();

  useEffect(()=>{
		if(infoUser){
      setNameWallet(infoUser.nombre.nombre+' '+infoUser.nombre.apellido);
      setUidUsuario(infoUser.usuario);
      setWalletID(infoUser.wallet);
      if(infoUser.rol !== 'asesor'){
        if(infoUser.rol === 'empresa'){setEmpresa(true); setEmpresaId(infoUser.empresaID)}
        let cuentas
        if(infoUser.cuentas){
          const results = Object.keys(infoUser.cuentas).map(key => 
            ({...infoUser.cuentas[key], indice: key}))
          cuentas = results
        }
        if(receivers.length > 0 || cuentas.length > 0){setDestinos(true);}
      }else{
        setRestringed(true)
        if(receivers.length > 0){setDestinos(true);}
      }
    }
  },[infoUser, receivers])

  useEffect(()=>{
    if(business){
      if(business.wallet){
        setWalletB(business.wallet)
      }else{
        setRestringed(true)
      }
      setWalletN(business.name)
    }
  },[business])

  const [wallet] = useGetWallet();
  useEffect(()=>{
    if(wallet.length > 0){
      setFondos(wallet[0].balance)
      setEmail(wallet[0].email)
    }
  },[wallet])

  //DATOS
  const fecha = new Date();
  const [referencia, setReferencia] = useState('');
  const [servicio, setServicio] = useState('Tradicional');
  //VALORES
  const [aud, setAud] = useState(0);
  const [total, setTotal] = useState(0);
  const [fee, setFee] = useState();
  const cost = false;

  const [codigoPromo, setCodigoPromo] = useState();
	const [tipoCode, setTipoCode] = useState('');

  useEffect(()=>{
    if(codigoPromo){
      setTipoCode(codigoPromo.descuento)
    }else{
      setTipoCode('')}
    },[codigoPromo])

  //VALIDACIONES
  const [spnAud, setSpnAud] = useState(false);
  const [spnRef, setSpnRef] = useState(false);
  const [altRef, setAltRef] = useState('');

  //CALCULO BALANCE
  useEffect(()=>{
    if(aud){
      setTotal(Math.ceil(aud + fee))
  }else{
    setTotal(0);
  }},[fee, aud, cost])
    
  const Validar = (e) =>{
    e.preventDefault();
    let destino
    if(!aud || aud === 0){setSpnAud(true); return;}
    if(aud < 100){setSpnAud(true); return;}
    if(total > fondos){setSpnAud(true); return;}
    if(servicio === 'Tradicional'){if(aud > 9999){setSpnAud(true); return;}}
    if(servicio === 'UltraRápido'){if(aud > 4999){setSpnAud(true); return;}}
    if (!referencia){setSpnRef(true); setAltRef('Por ingresa la razón del envío'); return;}
    if (referencia.length < 4){setSpnRef(true); setAltRef('Debe contener más de 5 letras'); return;}
    const datas = receiver.current.validarDestino();
    if (!datas){return}
    if (datas){destino = datas; setReceptor(datas)}
    OpenModal(
      <ModalValidar 
        accion={()=>Submit(destino)} 
        titulo={'Registro de Transferencia'}
        mensaje={'Estas segur@ de realizar esta transferencia?'}
        botonName={'Sí, Realizar'}
        botonClass={'btnGeneral primaryC'}
      />
    )
  }

  const Submit = (destino) =>{
    setProcesando(true);
    AddDischarge({
      data:{
        referency: referencia,
        date: getUnixTime(fecha),
        status: 'En Proceso',
        type: 'transferencia',
        service: servicio,
      
        nameWallet: empresa ? walletN : nameWallet,
        wallet: empresa ? walletB : walletID,
        userID: uidUsuario,
        ...empresa && {empresa: empresaID},
        email: email,
        values:{
          ammount: aud,
          fee: fee,
          total: total
        },
        balance:  fondos - total,

        receiverName: destino.receiverName,
        receiverID: destino.receiverID,
        receiver:{
          tipo: destino.tipoDno,
          nombre: destino.name,
          segNombre: destino.segName,
          apellido: destino.surName,
          segApellido: destino.segSurName,
          tipoId: destino.idType,
          numeroId: destino.idNumber,
          documento: destino.idDoc,
          email: destino.email,
          celular: destino.phone,
          pais: destino.country,
          ciudad: destino.city || '',
          direccion: destino.address
        },
        account:{
          bank: destino.bank,
          accountName: destino.accountName,
          bsb: destino.bsb,
          accountNumber: destino.accountNumber
        },
        ...codigoPromo && {promociones:{
					codigo: codigoPromo.id,
					tipo: codigoPromo.tipo,
					descuento: codigoPromo.descuento,
					...codigoPromo.agenciaId && {agenciaId: codigoPromo.agenciaId},
				}},
      }
    }).then(async(docRef) => {
      
      const datos = {
        type: 'transferencia',
        estado: 'En Proceso',
        email: email,
        id: docRef.id.substring(0, 6).toUpperCase(),
        nombre: empresa ? Capitalize(walletN) : Capitalize(nameWallet),
        fecha: dateEmail(getUnixTime(fecha)).toUpperCase(),
        metodo: servicio,
        fee: FormatAud(fee),
        aud: FormatAud(aud),
        total: FormatAud(total),
        razon: referencia,
        titular: Capitalize(destino.accountName),
        account: destino.accountNumber,
        bank: destino.bank,
        bsb: destino.bsb,
        msj: 'fue procesada con éxito. A continuación encontrarás la información.'
      }

      await axios.post('https://server-colcambios.herokuapp.com/api/billetera',{datos})

      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'Se ha registrado la transferencia y se encuentra en proceso.'} />);
      Navigate(`/mi-billetera/movimiento/transferencia/${docRef.id}`)
      
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    })
  }

  // const [open, setOpen] = useState(false);
  // const Toggle = () =>{ const valor = open ? false : true; setOpen(valor)}

  return <>
    <Helmet><title>Transferir</title></Helmet>
    <div className='dashPage'>
      <p className="pageTitle">Nueva Transferencia</p>
      {restringed ? <Restringed /> : fondos <= 0 ? <SinFondos />  : !destinos ? <AgregaUnContacto /> : 
        // <div className={open ? 'windowProcess bottomPadding' : 'windowProcess'}>
        <div className='windowProcess'>
          <form className={sideFix ? 'windowFormRowX' : 'windowForm windowLarge' }>
          <div className='windowImagen'>
            <SVG src={animation}/>
          </div>
          <div className='windowContent'>
            <InputDisabled label='Tu Saldo' value={FormatAud(fondos)} setValue={setFondos} />
            <InputAUD label={'¿Cuanto deseas Transferir?'} spn={spnAud} setSpn={setSpnAud} value={aud} setValue={setAud} fill={'off'} />
            
            <SetFeeV2 
              country={'AUD'}
              value={servicio}
              setValue={setServicio}
              fee={fee}
              setFee={setFee}
              promo={tipoCode}
              Format={FormatAud}
              sign={true}
            />


            <div className='optCalc'>
              <div className='optItem'><div className='simbolb'>=</div><span>
              <label>{FormatAud(total)}</label></span></div>
              <p>Total a Pagar</p>
            </div>
          
            <div className='InfoPoints'>
              {aud < 100 && <span>Monto mínimo: <b>$100 AUD</b></span>}
              {fondos < total && <span>Excede el Saldo de su Billetera</span>}
              {fee === 4.99 && aud > 9999 && <span>El monto excede el limite por día</span>}
              {fee === 14.99 && aud > 4999 && <span>El monto excede el limite por día</span>}
            </div>
            
            <BuscarPromo usuario={uidUsuario} setCodigo={setCodigoPromo} check={codigoPromo}/>	
            {/* 
            {fee !== 0 &&
              <SetButton title={'2. Costos del servicio'} value={cost} setValue={setCost} 
                setSpn={setSpnCost} spn={spnCost} alt={''}
                option1={'Servicio Incluído'} option2={'Adicionar el Servicio'}
                desc1={'Se descuenta el servicio del valor a recibir.'}
                desc2={'El servicio se adiciona al valor que deseas transferir.'} extra={''}
              />
            } */}

            <Input label={'Referencia'} value={referencia} setValue={setReferencia} spn={spnRef}
              setSpn={setSpnRef} alt={altRef} type={'text'} fill={'on'} />
            <SetReceiver ref={receiver} uid={uidUsuario} receptor={receptor} receivers={receivers} country={pais} />
            <BtnGeneral text={'Transferir'} img={ImgCheck} side={'L'} disabled={false} type="button" 
              action={Validar} clases={'primaryC'} large={false} />
          </div>
          </form>

          {/* <div className={sideFix ? 'windowResume resumePlain' : 'windowResume'}>
            <button className='btnResume' onClick={()=>{Toggle()}}>{open ? 'Ocultar Resumen' : 'Ver Resumen'}</button>
            <p className='cardShortTitle'>Resumen</p>
            <div className='shortInfo'>
              <p><span>Pagas</span>{FormatAud(total)}</p>
              <p><span>Recibes</span>{FormatAud(ammount)}</p>
            </div>
            <div className={open ? 'windowTicket' : 'windowTicket windowTicketNone'}>
              <div><span>Tu Saldo</span><span>{FormatAud(fondos)}</span></div>
              <div><span>Total a Retirar</span><span>{FormatAud(total)}</span></div>
              <div><span>Servicio</span>
                {tipoCode === 'Fee' ?
                <div className='DatosPromo'>
                  <span><label className='labeled'>{FormatAud(4.99)}</label><br/>¡GRATIS!</span>
                </div>
                :<span>{cost && '-'}{FormatAud(fee)}</span>}
              </div>

              <div className='lineUp'><span>Valor a Recibir</span><span>{FormatAud(ammount)}</span></div>
            </div>
            
          </div> */}
        </div>
      }
    </div>
  </>
}

export default Transferir;