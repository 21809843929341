import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import ImgInfo from '../../img/icons/info-circle.svg';
import { useConfig } from '../../context/ConfigsContext';
import { useTrans } from '../../context/TransferContext';
import Arrow from '../../img/icons/arrow-down.svg';

const SetFee = (props) => {

  const{
    value,
    setValue,
    fee,
    setFee,
    promo
  } = props;

  const {FormatAud} = useConfig();
  const {fees, feeMethod} = useTrans();

  useEffect(() => {
    if(promo === 'Fee'){
      setValue('Tradicional')
      setFee(0)
    }else{
      if(fee === undefined){
        if(feeMethod){
          if(fees.length > 0){
            const array = fees.filter(i=>i.name === feeMethod)
            setFee(array[0].value)
            setValue(array[0].name)
          }
        }else{
          if(fees.length > 0){
            setValue(fees[0].name)
            setFee(fees[0].value)
          }
        }
      }if(fee === 0){
        if(fees.length > 0){
          setValue(fees[0].name)
          setFee(fees[0].value)
        }
      }
    }
  }, [fees, fee, setValue, setFee, feeMethod, promo]);

  const [info, setInfo] = useState(false);
  const handleToggle = () =>{setInfo(!info)};
  const handleBtn = (valor, fee) =>{setValue(valor); setFee(fee)}

  return (
    <div className={'ProcessBtn'}>
      <div className='BtnCardHead'><h3>1. Tipo de Servicio</h3><SVG src={ImgInfo} onClick={handleToggle}/></div>
      {promo === 'Fee' ? 
        <div className='BtnCardBtnsA'>
          <button className='BtnCardOnly' type='button' onClick={()=>{}}><span>Tradicional</span><label>¡Gratis!</label></button>
        </div>
      :
      <div className={!info ? 'BtnCardBtnsA' : 'BtnCardBtns'}>
        {fees.length > 0 &&
          fees.map((item)=>{
            return(
              <button key={item.id} className={(value === item.name ? 'BtnCardA ': '' ) + (item.active === false ? 'BtnUnabled':'')} type='button'
                onClick={item.active === true ? ()=>handleBtn(item.name, item.value) : ()=>{}}>
                <span>{item.name}</span><label>{item.value === 0 ? '¡Gratis!' : FormatAud(item.value)}</label>
              </button>
            )
          }) 
        }
      </div>
      }
      <div className={!info ? 'BtnCardInfo' : 'BtnCardInfoA'}>
        {fees.length > 0 && fees.map((item)=>{
          return <p key={item.id} ><b>{item.name} ({FormatAud(item.value)}):</b> {item.desc}</p>
        })}
        <button type='button' onClick={()=>{setInfo(false)}}>Entendido!</button>
      </div>
    </div>
  )
}

const SetFeeV2 = (props) => {

  const{
    country,
    value,
    setValue,
    fee,
    setFee,
    promo,
    Format,
    sign
  } = props;

  const {fees, feeMethod, coins} = useTrans();

  const [show, setShow] = useState(false);
	const Selection = () =>{setShow(!show);}
	const LeaveSelection = () =>{setShow(false)}
  const handleBtn = (valor, fee) =>{setValue(valor); setFee(fee)}
	const handleClick = (e) => {
    setValue(e.currentTarget.dataset.valor);
    handleBtn(e.currentTarget.dataset.valor, e.currentTarget.dataset.price)
  }

  useEffect(() => {
    if(promo === 'Fee'){
      setValue('Tradicional')
      setFee(0)
    }else{
      if(fee === undefined){
        if(feeMethod){
          if(fees.length > 0){
            const array = fees.filter(i=>i.name === feeMethod)
            setFee(array[0].value)
            setValue(array[0].name)
          }
        }else{
          if(fees.length > 0){
            setValue(fees[0].name)
            setFee(fees[0].value)
          }
        }
      }if(fee === 0){
        if(fees.length > 0){
          setValue(fees[0].name)
          setFee(fees[0].value)
        }
      }
    }
  }, [fees, fee, setValue, setFee, feeMethod, promo]);
  
  return (
    <div className='optCalc'>
      <div className='optItem'><div className='simbolb'>{sign ? '+' : '-'}</div>{fee === 0 ? <span className='free'>GRATIS</span> : <span>{Format(fee)}</span>}</div>
      <div className='selectCalc' onClick={Selection} onMouseLeave={LeaveSelection}>
        <p>Servicio <b className='optCalcCheck'>{value} <SVG src={Arrow} /></b></p>
        {show &&
          <div className='optionsCalc'>
            {fees.length > 0 &&
              fees.map((item)=>{
                let status
              
                const news= coins.filter(a => a.to === country)
                status = news[0][item.field]
                
                return(
                  <div 
                    key={item.id} 
                    data-valor={item.name}
                    data-price={item.value} 
                    onClick={status === true ? handleClick : ()=>{}} 
                    className={status ? 'optionCalc' : 'optionCalc optionCalcNo'}>
                    <span>{item.name}</span><label>{item.value === 0 ? '¡Gratis!' : Format(item.value)}</label>
                  </div>
                )
              }) 
            }
          </div>
        }
      </div>
    </div>
  )
}

export {
  SetFee,
  SetFeeV2
}