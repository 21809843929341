import { db } from '../../firebase/firebaseConfig';
import { updateDoc, doc } from 'firebase/firestore';

const AddRates = async(datos) =>{
	return await updateDoc(doc(db, 'config', 'rates'),{
		...datos
	})
}

const AgregarRate = async(props) => {
	const{
		rate,
		descuento
	} = props;
	return await updateDoc(doc(db, 'config', 'rate'),{
		rate: rate,
		descuento: descuento
	});
}

const EstadoServicio = async({id, modo, status}) =>{
	const name = `${id}.${modo}`
	return await updateDoc(doc(db, 'config', 'coins'),{
		[name] : status
	});
}

const ServicioGratis = async({freeFee}) =>{
	return await updateDoc(doc(db, 'config', 'servicio'),{
		'tradicional.value' : freeFee ? 0 : 4.99
	});
}

const EstadoTasa = async({tasaAuto}) =>{
	return await updateDoc(doc(db, 'config', 'rate'),{
		tasaAuto: tasaAuto
	});
}

export {
	AddRates,
	AgregarRate, 
	EstadoServicio,
	EstadoTasa,
	ServicioGratis
};
