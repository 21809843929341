import React, { useEffect, useState } from 'react';
import Toggle from '../../../../components/inputs/Toggle';
import { ModalValidar } from '../../../../components/modales/Modales';
import { useConfig } from '../../../../context/ConfigsContext';
import { ServicioGratis } from '../../../data/AdminRate';
import { useTrans } from '../../../../context/TransferContext';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
 
const TradicionalFree = () => {

  const {OpenModal, setProcesando} = useConfig();
  const {fees} = useTrans();
  const [freeFee, setFreeFee] = useState();

  useEffect(() => {
    if(fees.length > 0){
      let dato
      if(fees[0].value === 0){dato = true}else{dato = false}
      setFreeFee(dato)
    }
  }, [fees]);

  const AbrirToggle2 = (e) =>{
		e.preventDefault();
		OpenModal(
			<ModalValidar 
			accion={ToggleStatus2} 
			titulo={'Fee Gratis'}
			mensaje={`¿Deseas ${freeFee ? 'Desactivar' : 'Activar'} el serivicio Gratis?`}
			botonName={`Sí,${freeFee ? ' Desactivar' : ' Activar'}`}
			botonClass={freeFee ? 'btnGeneral redC' : 'btnGeneral primaryC'}
		/>)
	}

  
  const ToggleStatus2 = () =>{
    setProcesando(true)
    const valor = freeFee ? false : true
    ServicioGratis({freeFee: valor})
    .then(async()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={`El servicio gratis ha sido ${valor ? 'Activado' : 'Desactivado'}`} />)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />)
    })
  }
  
  return (
    <div className="itemConfig">
      <Toggle estado={freeFee} accion={AbrirToggle2}/>
      <p>Tradicional Gratis</p>
    </div>
  );
}
 
export default TradicionalFree;