import { useState, useEffect } from 'react';
import { db } from '../../firebase/firebaseConfig';
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, startAfter, where, getAggregateFromServer, sum, getCountFromServer } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const useGetCodes = () =>{
  const [codigos, setCodigos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const collectionRef = collection(db, 'codigos')
    const docsSnap = () =>{
      onSnapshot(collectionRef, (querySnapshot) => {
        setCodigos(querySnapshot.docs.map((codigo) => {return {...codigo.data(), id: codigo.id} }));
        setLoading(false);
      })     
    } 
    docsSnap();
  },[]);

  return [codigos, loading]
}

const useGetCode = (id) =>{
  const Navigate = useNavigate()
  const [code, setCode] = useState();
  const [codeLoad, setCodeLoad] = useState(true);

  useEffect(()=>{
    if(id){
      const docsSnap = async() =>{
        await getDoc(doc(db, 'codigos', id))
        .then((docSnap) =>{
          setCodeLoad(false);setCode(docSnap.data())
        }).catch(()=>{
          Navigate('/transferencias');
        })
      }
      docsSnap();
    }
  },[id, Navigate]);

  return [code, codeLoad];
}

const useObtenerCodigos = (busqueda) =>{

	const {infoUser} = useAuth();
	const [codigos, setCodigos] = useState([]);
	const [cantidad, setCantidad] = useState(0);
	const [loading, setLoading] = useState(true);

	const [last, setLast] = useState(false);
  const [first, setFirst] = useState(true);
  const [primero, setPrimero] = useState('');
  const [ultimo, setUltimo] = useState('');

  const GetData = new Promise ((resolve) =>{
    let referidos;
    if(infoUser){
      if(infoUser.rol === 'admin'){
        const collectionRef = collection(db, 'referidos')
        const constraints = [orderBy('nombre','desc')]
        const consulta = query(collectionRef, ...constraints)
        const docsSnap = async() =>{
          onSnapshot(consulta, (querySnapshot) => {referidos = querySnapshot.docs.map((referido) => {return {...referido.data(), id: referido.id}})
          // referidos.forEach(element => {
          //   const collectionRef = collection(db, "comisiones");
          //   const constraints = [where('codigo','==', element.id), where('estado', '==', ['pendiente']),orderBy('fecha','desc')]
          //   const consulta = query(collectionRef, ...constraints)
          //   const docsSnap = async() =>{ await getAggregateFromServer(consulta, {
          //     porPagar: sum('comision')
          //   }).then((snapshot) =>{
          //     referidos = [{ ...element, porPagar: snapshot.data().porPagar}]
          //   })}
          //   docsSnap()
          // });
          // 
          resolve(referidos)
        })}
        docsSnap()
      }
    }
  });

  const Next = () =>{
    setLoading(true)
    const collectionRef = collection(db, 'referidos')
    const constraints = [startAfter(ultimo), limit(11), orderBy('nombre','asc')]

    const consulta = query(collectionRef, ...constraints.reverse())
    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = unitsTotal.slice(0,10)
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setCodigos(units)
      setLoading(false);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1])
      setFirst(true);

      if(unitsTotal.length === 11){setLast(true)}else{setLast(false);}
    }) }
    return docsSnap();
  }

  const After = () =>{
    setLoading(true)
    const collectionRef = collection(db, 'referidos')
    const constraints = [startAfter(primero), limit(11), orderBy('nombre','desc')]
    const consulta =  query(collectionRef, ...constraints.reverse())

    const docsSnap = () =>{onSnapshot(consulta, (querySnapshot) => {
      const unitsTotal = querySnapshot.docs
      const forHelp = querySnapshot.docs.slice(0,10).reverse()
      const units  = forHelp.map((item) => {return {...item.data(), id: item.id}});
      setCodigos(units)
      setLoading(false)
      setLast(true);

      setPrimero(forHelp[0])
      setUltimo(forHelp[forHelp.length-1]);

      if(unitsTotal.length === 11){setFirst(true);}else{setFirst(false);}

    }) }
    return docsSnap();
  }

	useEffect(() => {
		if(infoUser){
      if(infoUser.rol === 'admin'){
				const collectionRef = collection(db, 'referidos')
        const constraints = [orderBy('nombre','asc')]
				const q = query(collectionRef, ...constraints)
				const docsSnap = async() =>{ await getCountFromServer(q).then((snapshot)=>{setCantidad(snapshot.data().count)})} 
        docsSnap()
			}
		}
	}, [infoUser]);

  useEffect(() =>{
    if(infoUser){
      if(infoUser.rol === 'admin'){
        const collectionRef = collection(db, 'referidos')
        const constraints = [limit(11), orderBy('nombre','asc')]
        const queryInicial = query(collectionRef, ...constraints)
  
        const docsSnap = () =>{
          onSnapshot(queryInicial, (querySnapshot) => {
            const unitsTotal = querySnapshot.docs
            const forHelp = querySnapshot.docs.slice(0,10)
            const units  =  forHelp.map((item) => {return {...item.data(), id: item.id}});
            setCodigos(units)
            setLoading(false);
    
            setPrimero(forHelp[0])
            setUltimo(forHelp[forHelp.length-1]);
    
            setFirst(false)
            if(unitsTotal.length === 11){setLast(true);}else{setLast(false);}
        })}
        docsSnap()
      }
    }
  },[infoUser, busqueda]);

  return {codigos, loading, Next, After, last, first, cantidad, GetData}
}

const useGetCodigos = () =>{

  const [codigos, setCodigos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const collectionRef = collection(db, 'referidos')
    const docsSnap = async() =>{
      await getDocs(collectionRef)
      .then((snapshot) =>{
        setLoading(false);
        setCodigos(snapshot.docs.map((codigo) => {
          return {...codigo.data(), id: codigo.id}
        }));
      })     
    } 
    docsSnap();
  },[]);

  return [codigos, loading]
}

const useObtenerCodigo = (id) =>{

  const Navigate = useNavigate();
  const [codigo, setCodigo] = useState('');
  const [load, setLoad] = useState(true);

  useEffect(()=>{
    if(id){
      const docsSnap = async() =>{
      await getDoc(doc(db, 'referidos', id))
        .then((docSnap) =>{
          setLoad(false);setCodigo(docSnap.data())
        }).catch(()=>{
          Navigate('/transferencias');
        })
      }
      docsSnap();
    }
  },[id, Navigate]);

  return [codigo, load]
}

const useObtenerReferido = (codigo) =>{
  const [referido, setReferido] = useState('');
  const [cargando, setCargando] = useState(true);

	useEffect(()=>{
		if(codigo){
			const docsSnap = async() =>{
				await getDoc(doc(db, 'referidos', codigo))
					.then((docSnap) =>{
						setCargando(false); setReferido(docSnap.data().negociacion);
					}).catch(()=>{
						setCargando(false); setReferido('');
					})
			}
			docsSnap();
		}  
  },[codigo]);

  return [referido, cargando]
}

const useReferido = (id) =>{
  const {infoUser} = useAuth();
  const Navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [codigo, setCodigo] = useState('');

  useEffect(() => {
    if(infoUser.rol === 'admin'){
      if(id){
        const docsSnap = () =>{
          onSnapshot(doc(db, 'referidos', id),
          (doc) => {
            if(doc.exists()){
              setLoad(false); setCodigo(doc.data());
            }else{
              Navigate('/codigos/codigos-referidos')
            }
          })
        }
        docsSnap();
      }
    }
  }, [id, infoUser, Navigate]);

  return [codigo, load];
}

const useGetLiquidacion = (id, mes, ano) =>{
  const [liquidacion, setLiquidacion] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const collectionRef = collection(db, "liquidaciones");
    if(mes){
      const q1 = query(collectionRef, 
        where('codigo','==', id),
        where('fecha.mes', '==', mes),
        where('fecha.ano', '==', ano),
        orderBy('fechaPago','desc')
      );
      const docsSnap = async() =>{
        await getDocs(q1) 
        .then((snapshot) =>{
          setLoading(false);
          setLiquidacion(snapshot.docs.map((liquida) => {
            return {...liquida.data(), id: liquida.id}
          }));
        })     
      } 
      docsSnap();
    }else{
      const q1 = query(collectionRef, 
        where('codigo','==', id),
        orderBy('fechaPago','desc')
      );
      const docsSnap = async() =>{
        await getDocs(q1) 
        .then((snapshot) =>{
          setLoading(false);
          setLiquidacion(snapshot.docs.map((liquida) => {
            return {...liquida.data(), id: liquida.id}
          }));
        })     
      } 
      docsSnap();
    }
  },[id, mes, ano]);

  return [liquidacion, loading];
}

const useGetLiquidaciones = (id) =>{
  const [liquidaciones, setLiquidaciones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(id){
      const collectionRef = collection(db, "liquidaciones");
      const q1 = query(collectionRef, 
        where('codigo','==', id),
        orderBy('fechaPago','desc')
      );
      const docsSnap = async() =>{
        await getDocs(q1) 
          .then((snapshot) =>{
            setLoading(false);
          setLiquidaciones(snapshot.docs.map((liquidacion) => {
            return {...liquidacion.data(), id: liquidacion.id}
          }));
        })     
      } 
      docsSnap();
    }
  },[id]);

  return [liquidaciones, loading];
}

const useGetComissions = () =>{
	const [comisions, setComisions] = useState(0);

	useEffect(()=>{
		const collectionRef = collection(db, "comisiones");
		const q = query(collectionRef, 
			where('estado', '==', 'pendiente'),
			where('comision', '>', 0)
		);
		const docsSnap = async() =>{await getAggregateFromServer(q, {
			comisiones: sum('comision')
		}).then((snapshot) =>{
			setComisions(snapshot.data().comisiones)
		})}
		docsSnap()
	},[]);

	return {comisions}
}

export  {
  useObtenerCodigos, 
  useObtenerCodigo, 
  useObtenerReferido,
  useGetCodes,
  useGetCode,
  useReferido,
  useGetLiquidacion,
  useGetLiquidaciones,
  useGetCodigos,
  useGetComissions
};
