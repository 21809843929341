import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import 'jspdf-autotable';
import { useConfig } from '../../../context/ConfigsContext';
import { useGetComissions, useObtenerCodigos } from '../../hooks/useGetCodes';
import { EliminarReferido } from './components/EliminarCodigo';
import { AdminReferido } from './components/AdminCodigo';
import { LoadingCard} from '../../../components/loading/Loadings';
import { BtnActions, BtnIcon } from '../../../components/buttons/Boton';
import AllComision from './components/AllComision';

import ImgDelete from '../../../img/icons/trash.svg';
// import ImgEdit from '../../../img/icons/edit.svg';
import ImgAdd from '../../../img/icons/plus.svg';
import ImgFilter from '../../../img/icons/filter-square.svg';
import ItemCard from '../../../components/items/ItemCard';
import { FiltroFechas } from '../../../components/tablas/FiltroTrans';
import '../../../styles/table.css';
import PaginacionNew from '../../../components/paginacion/PaginacionNew';
import ExportarReferidos from './components/ExportarReferidos';

const CodigosReferidos = () =>{

	const {OpenModal, FormatAud, Capitalize} = useConfig();
	const Navigate = useNavigate();

	const [filtrar, setFiltrar] = useState(false);
	const [busqueda, setBusqueda] = useState({})
	const {codigos, loading, Next, After, last, first, cantidad, GetData} = useObtenerCodigos(busqueda);

	const {comisions} = useGetComissions();
  //DOWNLOAD PDF
	const AbrirBorrar = (e) =>{e.preventDefault(); OpenModal(<EliminarReferido codigo={e.currentTarget.dataset.id} />)}
	const AbrirAdmin = (e) =>{e.preventDefault(); OpenModal(<AdminReferido id={e.currentTarget.dataset.id} />)}
	const GoinTo = (link) =>{Navigate(`/codigos/codigo-referido/${link}`)}

  return(
    <div className='dashPage'>
    <Helmet><title>Códigos Referidos</title></Helmet>
		<p className='pageTitle'>Códigos Referidos</p>
      <div className='card'>
        <p className='cardTitle'>Códigos Referidos</p>
				<div className='itemsCards'>
					<ItemCard label={'Total por pagar'} value={FormatAud(comisions)} />
					<ItemCard label={'Códigos Creados'} value={cantidad} />
				</div>
				<div className='filtrosHeader'> 
					<BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
					<div className='CheckBoxs'>
						<BtnActions Clases={'BlueColor'} Action={AbrirAdmin} Img={ImgAdd} Text={'Agregar Referido'} Disbaled={false} />
					</div>
				</div>
				
				{filtrar && <FiltroFechas busqueda={setBusqueda} />}
        {loading ? <LoadingCard /> : codigos && codigos.length > 0 ? <>
          <div className="tablaGeneralDiv">
            <table className="tablaGeneral">
							<thead>
								<tr>
									<th>Código</th>
									<th>Nombre</th>
									<th>Id</th>
									<th className='tdRight'>Estado</th>
									<th className='tdRight'>Negociación</th>
									{/* <th></th> */}
									<th></th>
								</tr>
							</thead>
							<tbody> 
								{codigos.map((codigo) => {
									return(
										<tr key={codigo.id} onClick={()=>{GoinTo(codigo.id)}}>
											<td>{codigo.id}</td>
											<td>{Capitalize(codigo.nombre)}</td>
											<td>{codigo.tipoId} {codigo.numeroId}</td>
											<td>{codigo.estado}</td>
											<td>{codigo.negociacion}</td>
											{/* <td><BtnIcon Action={AbrirAdmin} Id={codigo.id} Type={'button'} Img={ImgEdit} /></td> */}
											<td><BtnIcon Action={AbrirBorrar} Id={codigo.id} Type={'button'} Img={ImgDelete} /></td>
										</tr>
									)})
								}
              </tbody>
            </table>
          </div>
					<div className="TableFooter">
						<ExportarReferidos Action={GetData} />
					</div>
					<div className="TableFooter">
						<PaginacionNew Next={Next} After={After} first={first} last={last} />
					</div>
        </> :
					<div className="itemsEmpty">
						<p>No se encontraron Códigos</p>
					</div>
        }
      </div>
      <AllComision />
  	</div>
  )
}

export default CodigosReferidos;