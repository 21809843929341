import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ItemCard from '../items/ItemCard';
import { BtnActions } from '../buttons/Boton';
import { FiltroLabel } from './FiltroLabel';
import ImgFilter from '../../img/icons/filter-square.svg';
import { LoadingCard } from '../loading/Loadings';
import { FiltroTrans } from './FiltroTrans';
import TransCard from './TransCard';
import { useConfig } from '../../context/ConfigsContext';
import SVG from 'react-inlinesvg';
import Check from '../../img/icons/tick-circle.svg';
import Thick from '../../img/icons/minus-cirlce.svg';
import ColorEstado from '../../functions/ColorEstado';
import ExportarTransferencias from '../../admin/views/transferencias/components/Exports';
import PaginacionNew from '../paginacion/PaginacionNew';
import ExportConsolidate from '../../admin/views/transferencias/functions/ExportConsolidate';
 
const TableColcambios = (props) => {

  const {
		head,
		table,
		filtros,
		setFiltros,
		setBusqueda,
		loading,
		transacciones,
    GetData,
		Next,
		After,
		first,
		last,
		exports
	} = props;
	
  const {dateAu, Capitalize, FormatCop, FormatAud, FormatNzd} = useConfig();

  const Navigate = useNavigate();

	const GoinTo = (link) =>{
		if(table !== 'transferencias'){
			Navigate(`/transferencia/${table}/${link}`)
		}else{
			Navigate(`/transferencia/australia/${link}`)
		}
	}

  const [total, setTotal] = useState(0);
	const [nPorPagar, setNPorPagar] = useState(0);
  const [filtrar, setFiltrar] = useState(false);

	const CoinTo = (number) =>{
		if(table === 'transferencias'){return FormatAud(number)}
		if(table === 'colombia'){return FormatCop(number)}
		if(table === 'newzealand'){return FormatNzd(number)}
	}

	const CoinFrom = (number) =>{
		if(table === 'transferencias'){return FormatCop(number)}
		if(table === 'colombia'){return FormatAud(number)}
		if(table === 'newzealand'){return FormatCop(number)}
	}

  return (
    <>
      {head && <div className='itemsCards'>
        <ItemCard label={'Por pagar'} value={nPorPagar} />
        <ItemCard label={'Total por pagar'} value={CoinTo(total)} />
      </div>}
      <div className='BtnBox'> 
				<BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} disabled={false} />
        {/* <BtnActions Clases={'GreenColor'} Action={()=>{OpenModal(<ExportarTransferencias />)}} Img={''} Text={'Exportar'} Disbaled={false} /> */}
        <FiltroLabel filtros={filtros} setFiltros={setFiltros} />
      </div>
      {filtrar && <FiltroTrans cerrar={setFiltrar} filtros={filtros} setFiltros={setFiltros} busqueda={setBusqueda} />}
       	{loading ? <LoadingCard /> : transacciones && transacciones.length > 0 ? <>
					<TransCard data={transacciones} GoinTo={GoinTo} CoinFrom={CoinFrom} CoinTo={CoinTo}/>
					<div className='tablaGeneralDiv'>
						<table className='tablaGeneral'>
							<thead>
								<tr>
									<th>Referencia</th>
									<th>Fecha</th>
									<th>Usuario y Destino</th>
									<th className='tdRight'>Tasa</th>
									<th className='tdRight'>Pagado</th>
									<th className='tdRight'>Reciben</th>
									<th className='tdCenter'>Revisiones</th>
									<th>Estado</th>
								</tr>
							</thead>
							<tbody>
							{transacciones.map((transaccion) => {
								let divMetodo = 'Tradicional';
								if(transaccion.transferencia.metodo !== 'Tradicional'){
									divMetodo = ('Referencia');
								}

								let nivel1 = false
								let nivel2 = false
								let nivel3 = false

								if(transaccion.revisiones){
									if(transaccion.revisiones.datos){nivel1 = true}
									if(transaccion.revisiones.valores){nivel2 = true}
									if(transaccion.revisiones.ingreso){nivel3 = true}
								}

								return(
									<tr key={transaccion.id} onClick={()=>{GoinTo(transaccion.id)}}>
										<td>
											<Link to={`/transferencia/${table==='transferencias' ? 'australia' : table}/${transaccion.id}`}>
												{transaccion.id.substring(0, 6).toUpperCase()}<div className={divMetodo}>{transaccion.transferencia.metodo}</div>
											</Link>
										</td>
										<td>{dateAu(transaccion.fecha).toUpperCase()}</td>
										<td>{Capitalize(transaccion.remitente.nombre+' '+transaccion.remitente.apellido)}<br/>
											{transaccion.destinatario.tipo === 'Empresa' ? Capitalize(transaccion.cuenta.titular) :
												transaccion.destinatario.tipo === 'Autoenvío' ? 'AutoEnvío' :
												Capitalize(transaccion.destinatario.nombre) + ' ' + Capitalize(transaccion.destinatario.apellido)}</td>
										<td className='tdRight'><span>{FormatCop(transaccion.transferencia.tasa)}</span></td>
										<td className='tdRight'><span>{CoinFrom(transaccion.transferencia.totalPagado ? transaccion.transferencia.totalPagado : transaccion.transferencia.from)}</span>
											<p className='smallText'>{transaccion.colcambios.banco}</p></td>
										<td className='tdRight'><span>{CoinTo(transaccion.transferencia.montoAud ? transaccion.transferencia.montoAud : transaccion.transferencia.to)}</span>
											<p className='smallText'>{transaccion.cuenta.banco}</p></td>
										<td className='tdCenter'>
											<div>
												{nivel1 ? <SVG src={Check} className="tabCheck" /> : <SVG src={Thick} className="tabThick" />}
												{nivel2 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
												{nivel3 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
											</div>
										</td>
										<td><span className={ColorEstado(transaccion.estado)+' marginR5'}>&#9679;</span>{transaccion.estado}</td>
									</tr>
								);
							})}
							</tbody>
						</table>
					</div>
					<div className="TableFooter">
						<div className="BtnBox">
							<ExportConsolidate dbTable={table} valorAPagar={setTotal} numero={setNPorPagar} />
							{exports && <ExportarTransferencias Action={GetData} />}
						</div>
					</div>
					<div className="TableFooter">
						<PaginacionNew Next={Next} After={After} first={first} last={last} />
					</div>
				</> :   
				<div className="itemsEmpty">
					<p>No se encontraron Transferencias</p>
				</div>
			}
    </>
      
  );
}

export default TableColcambios;