import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { InputDefault } from '../inputs/InputCoin';
import SelectorCalc from '../inputs/SelectorCalc';
import { SetFeeV2 } from '../forms/SetFee';
import { useConfig } from '../../context/ConfigsContext';
import { useTrans } from '../../context/TransferContext';
import { PopUpCustom } from '../mensajes/MensajesClient';
 
const Calculator = forwardRef((props, ref) => {

  const {values, pais, codigoPromo} = props;
  useImperativeHandle(ref, ()=>{return {calcular: Calcular}})

  const { tasas } = useTrans();
  const { FormatCop, FormatAud, FormatNzd, OpenModal, toUp } = useConfig();

  const [change, setChange] = useState(false);
	const [loadTo, setLoadTo] = useState(true);
	const [loadFrom, setLoadFrom] = useState(false);

  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [tasa, setTasa] = useState(0);
  const [tasaOld, setTasaOld] = useState(0);
  const [fee, setFee] = useState();
  const [impuesto, setImpuesto] = useState(0);
	const [method, setMethod] = useState('');
	const [cuatromil, setCuatroMil] = useState(0);

  const [bdName, setBdName] = useState('');
  const [tipoCode, setTipoCode] = useState('');
  const [country, setCountry] = useState('AUD');
	const [countryFrom, setCountryFrom] = useState('COP');

  const [metodoPago, setMetodoPago] = useState('Transferencia'); //true otro false transferencia
	const metodos = [{id: 1, text: 'Transferencia', text2: 'Sin Costo Adicional'}, {id:2, text: 'Otro Método de Pago', text2:'Costo Adicional'}]
	const metodosAu = [{id: 1, text: 'Transferencia', text2: 'Sin Costo Adicional'}]

  const [spnTo, setSpnTo] = useState(false);
	const [spnFrom, setSpnFrom] = useState(false);
	const [altTo, setAltTo] = useState('');

  const FormatFrom = (numero) =>{
		if(country === 'COP'){return FormatAud(numero)}
		if(country === 'AUD'){return FormatCop(numero)}
		if(country === 'NZD'){return FormatCop(numero)}
	}

  const FormatFee = (numero) =>{
		if(country === 'COP'){return FormatAud(numero)}
		if(country === 'AUD'){return FormatAud(numero)}
		if(country === 'NZD'){return FormatNzd(numero)}
	}

  useEffect(()=>{if(pais){setCountry(pais)}},[pais])

  useEffect(() => {
    if(values){
      setFrom(values.from)
      setTo(values.to)
      setTasa(values.tasa)
      setFee(values.fee)
      setImpuesto(values.impuesto)
      setMethod(values.method)
      setCuatroMil(values.cuatroMil)
    }else{
      if(country === 'COP'){setFrom(1000);setLoadFrom(false)}
      if(country === 'AUD'){setFrom(1000000);setLoadFrom(false)}
      if(country === 'NZD'){setFrom(1000000);setLoadFrom(false)}
    }
  }, [values, country]);

  useEffect(() => {
		if(country){
			if(country === 'COP'){setBdName('colombia'); setCountryFrom('AUD')}
			if(country === 'AUD'){setBdName('transferencias'); setCountryFrom('COP');}
			if(country === 'NZD'){setBdName('newzealand'); setCountryFrom('COP');}
		}
	}, [country]);

  useEffect(() => {
		if(tasas){
			if(codigoPromo){
				setTipoCode(codigoPromo.descuento)
				if(codigoPromo.descuento === 'Tasa'){
					if(country === 'COP'){
						setTasa(tasas.AUD.compra + codigoPromo.valor); setTasaOld(tasas.AUD.compra)
					}
					if(country === 'AUD'){
						setTasa(tasas.AUD.venta - codigoPromo.valor); setTasaOld(tasas.AUD.venta)
					}
					if(country === 'NZD'){
						setTasa(tasas.NZD.venta - codigoPromo.valor); setTasaOld(tasas.NZD.venta)
					}
				}
			}else{
        setTipoCode()
				if(country === 'COP'){
					setTasa(tasas.AUD.compra); setTasaOld();
				}
				if(country === 'AUD'){
					setTasa(tasas.AUD.venta); setTasaOld();
				}
				if(country === 'NZD'){
					setTasa(tasas.NZD.venta); setTasaOld();
				}
			}
		}
	}, [country, tasas, codigoPromo]);

  useEffect(()=>{
		let valorA 
		let valorT
		let valorMil 

		if(country === 'COP'){
			if(!change){
				valorA = ((from - fee) * tasa)
				valorMil = valorA * 4 / 1000
				setCuatroMil(Math.trunc(valorMil))
				setTo(Math.trunc(valorA - valorMil));
			}else{
				valorMil = to * 4 / 1000
				valorT = ((to + valorMil) / tasa) + fee
				setCuatroMil(Math.trunc(valorMil))
				setFrom(Math.ceil(valorT))
			}
		}else{
			if(!change){
				valorMil = from * 4 / 1000
				valorA = ((from - valorMil - impuesto) / tasa) - fee
				setCuatroMil(Math.trunc(valorMil))
				setTo(Math.trunc(valorA))
			}else{
				valorT = (to * tasa) + impuesto  + (fee * tasa)
				valorMil = valorT * 4 / 1000
				setCuatroMil(Math.trunc(valorMil))
				setFrom(Math.trunc(valorT + valorMil));
			}
		}
  },[country, from, to, change, tasa, fee, impuesto])

	//IMPUESTO

	useEffect(() => {
		if(metodoPago === 'Transferencia'){
			setImpuesto(0)
		}else{
      setMetodoPago('Otro')
      setImpuesto(20000);
    }
	}, [metodoPago]);

  const Calcular = () =>{
    const datas = {
      country: country,
      bdName: bdName,
      from: from,
      to: to,
      method: method,
      fee: fee,
      impuesto: impuesto,
      tasa: tasa,
      cuatromil: cuatromil,
    }

    if(country === 'COP'){
			if (from < 300){toUp(); setSpnFrom(true); setAltTo('Debe ser mayor a 300'); return;}
			if (from > 5000){
				OpenModal(<PopUpCustom mensaje={'El límite diario en el método Tradicional son $5.000 AUD, contáctanos'} 
				img={''} title={'Límite Diario'} />); toUp(); return;
      }
			return datas
		}
		if(country === 'AUD'){
			if (to < 100){ toUp(); setSpnTo(true); setAltTo('Debe ser mayor a 100'); return;}
			if (to > 5000){if(fee===14.99){
				OpenModal(<PopUpCustom mensaje={'El límite diario en el método UltraRápido son $5.000 AUD, contáctanos'} 
					img={''} title={'Límite Diario'} />); toUp(); return;}}
			if(to > 10000){
				OpenModal(<PopUpCustom mensaje={'El límite diario en el método Tradicional son $10.000 AUD, contáctanos'} 
				img={''} title={'Límite Diario'} />); toUp(); return;}
      return datas
		}
		if(country === 'NZD'){
			if (to < 100){ toUp(); setSpnTo(true); setAltTo('Debe ser mayor a 100'); return;}
			if (to > 5000){if(fee===14.99){
				OpenModal(<PopUpCustom mensaje={'El límite diario en el método UltraRápido son $5.000 NZD, contáctanos'} 
					img={''} title={'Límite Diario'} />); toUp(); return;}}
			if(to > 10000){
				OpenModal(<PopUpCustom mensaje={'El límite diario en el método Tradicional son $10.000 NZD, contáctanos'} 
				img={''} title={'Límite Diario'} />); toUp(); return;}
      return datas
   }
  }

  return (
    <div className='newCalc'>
      <InputDefault
        label={'Envías'}
        spn={spnFrom}
        setSpn={setSpnFrom}
        value={from}
        setValue={setFrom}
        onClick={()=>{setChange(false); if(change === true){setLoadTo(true); setTimeout(() => {setLoadTo(false)}, 1000);}}}
        coin={countryFrom} 
        disabled={false}
        load={loadFrom}
        setOther={setLoadTo}
      />
      {altTo && <span className='warningCalculator'>{altTo}</span>}
    
      <div className='optsCalc'>
        <div className='optCalc'>
          <div className='optItem'><div className='simbolb'>-</div><span>{FormatFrom(impuesto)}</span></div>
          <SelectorCalc label={'Método de Pago'} value={metodoPago} setValue={setMetodoPago} options={country === 'COP' ? metodosAu : metodos} />
        </div>
        <div className={country === 'COP' ? 'reverseFlex' : 'reverseNo'}>
          <div className='optCalc'>
            <div className='optItem'><div className='simbolb'>-</div><span>{FormatCop(cuatromil)}</span></div>
            <p>Impuesto 4x1000</p>
          </div>
          <div className={country === 'COP' ? 'reverseFlex' : 'reverseNo'}>
            <div className='optCalc'>
              <div className='optItem'><div className='simbolb'>{country === 'COP' ? '×':'÷'}</div><span>{tasaOld && <label className={tipoCode === 'Tasa' ? 'labeled' : ''}>{FormatCop(tasaOld)}</label> }
              <label>{FormatCop(tasa)}</label></span></div>
              <p>Tasa de Cambio</p>
            </div>

            <SetFeeV2 
              country={country}
              value={method}
              setValue={setMethod}
              fee={fee}
              setFee={setFee}
              promo={tipoCode}
              Format={FormatFee}
              sign={false}
            />
          </div>
        </div>
      </div>

      <InputDefault
        label={'El Destinatario Recibe'}
        spn={spnTo}
        setSpn={setSpnTo}
        value={to}
        setValue={setTo}
        onClick={() =>{setChange(true); if(change === false){setLoadFrom(true); setTimeout(() => {setLoadFrom(false)}, 1000);}}}
        coin={country}
        disabled={false}
        load = {loadTo}
        setOther={setLoadFrom}
      />
    </div>
  );
})
 
export default Calculator;