import saveAs from 'file-saver';
import logoCol from '../../../../img/ColLogo';
import { Workbook } from 'exceljs';

const ExportComisiones = async(props)=>{

    const { trapData, codigoId } = props

    const title = `Comisiones de ${codigoId}`;
    const header = ["Referencia","Fecha","Nombre","Comisión","Estado", "Transferencia"];

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Comisiones');

    let titleRow = worksheet.addRow([title], 'B1:B2');
    titleRow.font = { size: 16, bold: true };
    worksheet.addRow([]);

    let logo = workbook.addImage({
        base64: logoCol,
        extension: 'png',
    });
    worksheet.addImage(logo, 'A1:A2');
    worksheet.mergeCells('A1:F2');

    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);

    headerRow.font = {color:{argb: 'FFFFFFFF'},bold: true }
    headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0018B4' },
        };
      });

    worksheet.autoFilter = {
        from: {
            row: 4,
            column: 1
        },
        to: {
            row: 4,
            column: header.length
        }
    };

    await trapData.forEach(d => {
        const row = worksheet.addRow(d);
        
        row.eachCell((cell, number) => {
            cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: 'FFFFFFFF'
            };
            cell.border = { color:{ argb: 'FF000000' }, top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        });
        
    });

    worksheet.getRow(1).height=70;
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.getColumn(1).width = 18;
    worksheet.getColumn(2).width = 18;
    worksheet.getColumn(3).width = 18;
    worksheet.getColumn(4).width = 18;
    worksheet.getColumn(5).width = 18;
    worksheet.getColumn(6).width = 20;

    const suma = worksheet.addRow();
    suma.getCell(1).value = 'Total Comisiones'
    suma.getCell(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    // suma.getCell(4).value = totalMes
    suma.getCell(5).value = ''
    suma.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: 'FFECECEC'
        };
        cell.border = { 
            color:{ argb: 'FF000000' }, 
            top: { style: 'thin' }, 
            left: { style: 'thin' }, 
            bottom: { style: 'thin' }, 
            right: { style: 'thin' } 
        };
    })

    worksheet.mergeCells(`A${suma.number}:C${suma.number}`)

    const footerRow = worksheet.addRow(['Exportado desde Colcambios Australia']);
    footerRow.getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFECECEC' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `Comisiones ${codigoId}.xlsx`);
    });
      
}

export default ExportComisiones;