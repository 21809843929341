import React, { useRef, useState } from 'react';

import { BtnGeneral } from '../buttons/Boton';
import ImgSend from '../../img/icons/send.svg';
import { useTrans } from '../../context/TransferContext';
import { useNavigate } from 'react-router-dom';
import { CoinsSelect } from '../../views/pages/transferencia/components/CoinsMenu';
import Calculator from './Calculator';

const CalculadoraInicio = () => {

  const navigate = useNavigate();
  const {setAmmounts} = useTrans();
	const calculadora = useRef();
  const [country, setCountry] = useState('');

  const Enviar = () =>{
    const datas = calculadora.current.calcular();
		if (!datas){return}
    if (datas){setAmmounts(datas)}
    navigate('/nueva-transferencia');
  }

  return (
    <div className='card'>
      <p className='cardTitle'>Calcula tu Transferenciaaa</p>
      <CoinsSelect country={country} setCountry={setCountry} />
      <Calculator ref={calculadora} values={''} pais={country} codigoPromo={''} />
      <BtnGeneral text='Realizar Transferencia' img={ImgSend} side={'L'} disabled={false} type="button" action={Enviar} clases={'primaryC'} large={true} />
    </div>
  );
}
 
export default CalculadoraInicio;