import React, { useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import ImgNext from '../../../../img/icons/arrow-right-1.svg';
import arrowDown from '../../../../img/icons/arrow-down.svg';
import co from '../../../../img/icons/co.svg';
import au from '../../../../img/icons/au.svg';
import nz from '../../../../img/icons/nz.svg';
import { useTrans } from '../../../../context/TransferContext';
 
const CoinsMenu = (props) => {
  
  const { coins, action } = props;

  return ( <>
    <p className='titleForm'>Enviar Dinero</p>
    <div className='cardsCountries'>
      {coins.map((coin)=>{
        let iconTo;
        let iconFrom;

        if(coin.to){
          if(coin.to === 'COP'){iconTo = co}
          if(coin.to === 'AUD'){iconTo = au}
          if(coin.to === 'NZD'){iconTo = nz}
        }

        if(coin.from){
          if(coin.from === 'COP'){iconFrom = co}
          if(coin.from === 'AUD'){iconFrom = au}
        }

        return (
          <div className={coin.status ? 'coinDivActive' : 'coinDivDisable'} key={coin.id}>
            <button onClick={()=>{action(coin.to)}} type={'button'} className={coin.status ? 'coinActive' : 'coinDisable'} disabled={!coin.status}>
              <SVG src={iconFrom} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={iconTo} /><span>De <b>{coin.textFrom}</b> a <b>{coin.textTo}</b></span>
            </button>
          </div>
        )
      })
    }
    </div>
  </>);
}

const CoinsSelect = (props) =>{
  
  const { country, setCountry } = props;
  const {coins} = useTrans();
  const [value, setValue] = useState();

  const icon = (name) =>{
    if(name === 'COP'){return co}
    if(name === 'AUD'){return au}
    if(name === 'NZD'){return nz}
  }

  const coinFormat = useCallback((text) => {
    coins.filter(a => a.to === text).map(coin =>{
      let data = {
        iconFrom: icon(coin.from),
        iconTo: icon(coin.to),
        textTo: coin.textTo,
        textFrom: coin.textFrom
      }
      return setValue(data)
    })
  }, [coins]);
 
  useEffect(() => {
    if(coins.length > 0){
      if(country){
        coinFormat(country)
      }else{
        setValue({
          iconFrom: icon(coins[0].from),
          iconTo: icon(coins[0].to),
          textTo: coins[0].textTo,
          textFrom: coins[0].textFrom,
        })
      }
    }
   
  }, [country, coins, coinFormat]);

  const [show, setShow] = useState(false);
	const Selection = () =>{setShow(!show);}
	const LeaveSelection = () =>{setShow(false)}
	const handleClick = (e) => {setCountry(e.currentTarget.dataset.valor)}

  return(
    <div className='selectCoin' onClick={Selection} onMouseLeave={LeaveSelection}>
      {value && <div className={show ? 'coinChoosed borderTop' : 'coinChoosed' } >
        <SVG src={value.iconFrom} /><SVG src={ImgNext} className='arrowCoin' /><SVG src={value.iconTo} />
        <span>De <b>{value.textFrom}</b> a <b>{value.textTo}</b></span>
        <div className='CoinArrowDown'><SVG src={arrowDown} /></div>
      </div>}
      {show &&
        <div className='coinsOptions'>
          {coins.map((coin)=>{
            return(
              <div
                key={coin.id}
                data-valor={coin.to} 
                onClick={coin.status ? handleClick : ()=>{}} 
                className={coin.status ? 'coinOption' : 'coinOption coinOptionUnable'}
              >
                <SVG src={icon(coin.from)} /><SVG src={ImgNext} className='arrowCoin' /><SVG src={icon(coin.to)} /><span>De <b>{coin.textFrom}</b> a <b>{coin.textTo}</b>
                <br/>{!coin.status && <label className='labelUnabled'>No Disponible</label>}</span>
              </div>
            )
          }
        )}
        </div>
      }
    </div>
  )
}
 
export {
  CoinsMenu,
  CoinsSelect
}