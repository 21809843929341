import React, { useEffect, useState}  from 'react'
import { storage } from '../../../../firebase/firebaseConfig';
import FileDocument from '../../../../components/upload/FileDocument';
import { ModalValidar } from '../../../../components/modales/Modales';
import { AddPagosComisiones, AddPagoComision, AgregarLista } from '../../../data/AdminComisiones';
import getUnixTime from 'date-fns/getUnixTime';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { useConfig } from '../../../../context/ConfigsContext';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import ImgPlus from '../../../../img/icons/tick-square.svg';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

const SubirLiquidacion = (props) => {

  const{
    id,
    ids,
    setIds,
    saldo,
    setSaldo,
    setSpnAmmount,
    setAltAmmount,
  }= props;

  const {OpenModal, setProcesando} = useConfig();
  const [file, setFile] = useState();
  const [altFile, setAltFile] = useState('');
  const [spnFile, setSpnFile] = useState(false);
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {setSpnFile(false);}, [file]);

  const Validar = (e) =>{
    e.preventDefault();
    if(!saldo){setSpnAmmount(true); setAltAmmount('Ingresa un Valor'); return;}
    if(saldo === 0){setSpnAmmount(true); setAltAmmount('Ingresa un Valor'); return;}
    if(!file){setSpnFile(true); setAltFile('Por favor selecciona el comprobante'); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnFile(true); 
      setAltFile('Archivo no válido, solo Imagen o PDF');return;
    }
    const size = Math.round((file[0].size / 1024));
    if(size >= 1024) {setSpnFile(true); setAltFile('El tamaño máximo del archivo es de 1mb'); return;}
    OpenModal(
      <ModalValidar
        accion={SubirComprobante}
        titulo={"Cargar Liquidación"}
        mensaje={"¿Estás seguro de liquidar estas comisiones?"}
        botonName={"Sí, Cargar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  }

  const SubirComprobante = ()=>{
    setProcesando(true)
    const fechaPago = new Date();
    AddPagosComisiones({
      codigo: id, 
      fechaPago: getUnixTime(fechaPago),
      pagadas: ids,
      ammount: saldo
    }).then(async(docRef)=>{
      const pago =  docRef.id;
      const comprobante = file[0];
      const nombreArchivo = `comprobante${pago}.${comprobante.name.split(".").pop()}`;
      const storageRef =  ref(storage, `liquidaciones/${id}/${nombreArchivo}`)
      const uploadTask = uploadBytesResumable(storageRef, comprobante);
      uploadTask.on("state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(progress);
        },
        (error) => {
          setProcesando(false)
          OpenModal(<HuboUnError />);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            AddPagoComision({
              id: pago,
              documento: url,
            }).then(async()=>{
                await AgregarLista({id: pago, pagadas: ids})
                setProcesando(false)
                OpenModal(<PopUpValid mensaje={'Se ha cargado el comprobante Correctamente'} />);
                setSaldo(0); setFile(); setIds([]); setSaldo(0)
            })
          })
        }
      )
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    })
  }

  return (
    <form onSubmit={Validar}>
      <FileDocument 
        archivosSelec={setFile} 
        spnAltArchivo={spnFile} 
        altArchivo={altFile} 
      />
      <div className="noSee"><progress value={progress} max="100"></progress></div>
      <BtnGeneral 
        text='Subir Comprobante'
        img={ImgPlus}
        side={'L'}
        disabled={false}
        type="submit"
        action={()=>{}}
        clases={'primaryC'} large={false}
      />
    </form>
  )
}

export default SubirLiquidacion