import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useReferido } from '../../hooks/useGetCodes';
import { ComisionesUser } from './components/ComisionesUser';
import Liquidaciones from './components/Liquidaciones';
import { LoadingCard } from '../../../components/loading/Loadings';
import { BtnGeneral } from '../../../components/buttons/Boton';
import ImgDelete from '../../../img/icons/trash.svg';
import { useConfig } from '../../../context/ConfigsContext';
import { EliminarReferido } from './components/EliminarCodigo';

const CodigoReferido = () =>{

  const {id} = useParams();
  const {OpenModal} = useConfig();
  const navigate = useNavigate();
    
  const [ids, setIds] = useState([]);
  const [saldo, setSaldo] = useState(0);
    
  const [codigo, load] = useReferido(id);
  const [nombre, setNombre] = useState();
  const [tipo, setTipo] = useState();
  const [estado, setEstado] = useState();
  const [numeroId, setNumeroId] = useState();
  const [tipoId, setTipoId] = useState();
  const [negociacion, setNegociacion] = useState();
  
  const AbrirBorrar = (e) =>{e.preventDefault(); OpenModal(<EliminarReferido codigo={id} />)}

  useEffect(() => {
    if(codigo){
      setEstado(codigo.estado)
      setNegociacion(codigo.negociacion)
      setNombre(codigo.nombre)
      setNumeroId(codigo.numeroId)
      setTipoId(codigo.tipoId)
      setTipo(codigo.tipo)
    } 
  }, [codigo, navigate]);

  return(
    <div className='dashPage'>
      <Helmet><title>Código Referido</title></Helmet>
			<p className='pageTitle'>Referido</p>
      <div className="cardFlex">
        <div className="card70">
					<div className="card">
						{load ? <LoadingCard /> : <>
							<p className='cardTitle'>Información</p>
								<div className="cardData">
									<div><p><label>Código</label>{id}</p></div>
									<div><p><label>Estado</label>{estado}</p></div>
									<div><p><label>Nombre</label>{nombre}</p></div>
									<div><p><label>Tipo</label>{tipo}</p></div>
									<div><p><label>Id</label>{(tipoId+' '+numeroId)}</p></div>
									<div><p><label>Negociación</label>{negociacion}</p></div>
							</div>
						</>}
					</div>
          
        </div>
			<div className="card30">
				<Liquidaciones 
					id={id}
					ids={ids}
					saldo={saldo}
					setIds={setIds} 
					setSaldo={setSaldo} 
				/>
        <div className="card">
          <p className='cardTitle'>Eliminar Referido</p>
          <BtnGeneral text={'Eliminar'} img={ImgDelete} side={'L'} disabled={false} type="button" 
            action={AbrirBorrar} clases={'redC'} large={true} />
        </div>
			</div>
      <ComisionesUser 
        codigoId={id}
        setIds={setIds}
        ids={ids} 
        setSaldo={setSaldo} 
      />

		</div>
    </div>
  )
}

export default CodigoReferido