import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useSetEmpresa } from '../../hooks/useEmpresas';
import TransferenciasUsario from '../usuarios/components/TransferenciasUsuario';
import DocId from '../../../img/icons/documentid.svg';
import { LoadingCard } from '../../../components/loading/Loadings';
import SVG from 'react-inlinesvg'
import { useConfig } from '../../../context/ConfigsContext';
import LogoWeb from './components/LogoWeb';
import { Adgents, Ceo } from './components/ManageEmployes';

const Empresa = () => {

	const navigate = useNavigate();
	const { id } = useParams();
	const [business, load] = useSetEmpresa(id);

  const {infoUser} = useAuth();
	const [rol, setRol] = useState(false);
	useEffect(()=>{ if(infoUser.rol === 'admin'){setRol(true);}},[infoUser])

	const {dateFormat} = useConfig();

	//DATOS
	const [name, setName] = useState('');
	const [code, setCode] = useState('');
	// const [address, setAddress] = useState({});
	const [account, setAccount] = useState({});
	const [ceo, setCeo] = useState();
  const [registerDate, setRegisterDate] = useState('');
	const [deal, setDeal] = useState('');
	const [logoWeb, setLogoWeb] = useState('');

	useEffect(() => {
		if(business){
			if(rol){
				setName(business.name);
				setCode(business.code)
				setAccount(business.account)
				setCeo(business.ceo)
				setRegisterDate(dateFormat(business.registerDate))
			
				setLogoWeb(business.logoWeb);
				setDeal(business.deal)
			} else{
				navigate('/usuarios');
			}
		}
	}, [business, navigate, rol, dateFormat]);

  return (
    <div className='dashPage'>
			<Helmet><title>Empresa</title></Helmet>
			<p className='pageTitle'>Empresa</p>
			{load ? <LoadingCard /> : business &&
			<div className='cardFlex'>
				<div className='card70'>
					{!logoWeb && <LogoWeb setLogo={setLogoWeb} name={name} id={id} />}
					<div className="card">
						<p className='cardTitle'>Información</p>
						<div className="cardData">
							<div>{logoWeb && <img src={logoWeb} alt='logo'/>}</div>
							<div><p><label>Nombre Legal</label>{business.document.legalName}</p></div>
							<div><p><label>{business.document.typeID}</label>{business.document.numberID}</p></div>
						</div>
					</div>
					<div className="card">
						<p className='cardTitle'>Contacto</p>
						<div className="cardData">
							<div><p><label>Email</label>{business.contact.email}</p></div>
							<div><p><label>Celular</label>
								<a href={`https://wa.me/${business.contact.phones[0]}`} target="blank_" rel="noopener noreferrer">{business.contact.phones[0]}</a></p>
							</div>
						</div>
					</div>

					<div className='card'>
						<p className='cardTitle'>Cuenta</p>
							<div className="cardData">
								<div><p><label>Titular</label>{account.titular}</p></div>
								<div><p><label>Banco</label>{account.bank}</p></div>
								{account.country === 'Colombia' && 
									<div><p><label>Tipo de Cuenta</label>{account.type}</p></div>
								}
								{account.country === 'Australia' && 
									<div><p><label>Número BSB</label>{account.bsb}</p></div>
								}
								<div><p><label>No. de Cuenta</label>{account.account}</p></div>
							</div>
					</div>

					<div className="card">
							<p className='cardTitle'>Referido</p>
							<div className="cardData">
								<div><p><label>Código Referido</label>{code}</p></div>
								<div><p><label>Negociación</label>{deal}</p></div>
							</div>
						</div>

				</div>
				<div className='card30'>
					<div className='card'>
						<p className='cardTitle'>Registros</p>
						<div className="cardData">
							<div className='cardData100'><p><label>Fecha de Registro</label>{registerDate}</p></div>
						</div>
					</div>

					<div className="card">
						<p className='cardTitle'>Documentos</p>
						<div className="docItem">
							<a href={business.document.url} target="blank_"><SVG src={DocId}/>{business.document.typeID+' '+business.document.numberID}</a>
						</div>
					</div>

					<div className='card'>
						<p className='cardTitle'>Ceo</p>
						
					</div>
				</div>
			</div>
			}

			{!ceo && <Ceo empresa={id} />}
			<Adgents empresa={id} />
			<TransferenciasUsario id={id} empresa={true} />
		</div>
  )
}

export default Empresa