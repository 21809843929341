import React, { useEffect, useState } from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import { AddMenu, BorrarMenu } from '../../../data/AdminConfig';
import Item from './Item';
import { Input } from '../../../../components/inputs/Input';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import SVG from 'react-inlinesvg';
import ImgPlus from '../../../../img/icons/add-square.svg'
import Check from '../../../../img/icons/tick-square.svg';
import './itemMenu.css';
 
const ItemPrincipal = ({setClose, list, listName, setMenu}) => {
  const {OpenModal, setProcesando} = useConfig();

  const [name, setName] = useState('');
  const [spnName, setSpnName] = useState(false);
  const [altName, setAltName] = useState('');

  const [data, setData] = useState([]);

  const handleAdd = () =>{
    const newItem = {
      id: '',
      text: '',
      order: '',
      link: '',
      img: '',
      status: 'active'
    }
    setData([...data, newItem])
  }

  const handleRemove = (uid) =>{
    const Items = data.filter((item, i)=> i !== uid);
    setData(Items)
  }

  const handleEdit = (uid, datos) =>{
    const Items = data.map((item,i) =>{
      if(i === uid){return {...item, ...datos}}
      return item;
    })
    setData(Items)
  }

  const Eliminar = (e) =>{
    e.preventDefault();
    OpenModal(
      <ModalValidar 
        accion={BorrarPrincipal} 
        titulo={'Eliminar Menú'}
        mensaje={'¿Deseas Eliminar este menú?'}
        botonName={'Sí, Eliminar'}
        botonClass={'btnGeneral redC'}
      />)
  }

  const BorrarPrincipal = () =>{
    BorrarMenu(name).then(()=>{
      setMenu('')
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha eliminado Correctamente'}/>);
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  const Validar = (e) =>{
    e.preventDefault();
    if(name === ''){setSpnName(true); setAltName('Ingresa el Nombre'); return;}
    if(data.length === 0){return;}
    OpenModal(
      <ModalValidar 
        accion={Submit} 
        titulo={'Guardar Menú'}
        mensaje={'¿Deseas Agregar este nuevo menú?'}
        botonName={'Sí, Guardar'}
        botonClass={'btnGeneral primaryC'}
      />)
  }

  const Submit = () =>{
    setProcesando(true)
    AddMenu({
      name: name,
      data: data
    }).then(() => {
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'Se ha actualizado Correctamente'}/>);
      setClose(false)
    }).catch(() => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  useEffect(()=>{
    if(listName){
      setName(listName)
      setData(list[listName])
    }
  },[list, listName])

  return (
    <div className='itemMenu'>
      <Input label={'Nombre'} value={name} setValue={setName} spn={spnName}
        setSpn={setSpnName} alt={altName} type={'text'} fill={'on'} name="id" />

      {data && data.length > 0 && data.sort((a,b) => a.order - b.order).map((item, i)=>{
        return <Item item={item} i={i} action={handleRemove} edit={handleEdit} key={i} />
      })}

      <button className="buttonAdd" onClick={handleAdd} type='button'>
        <SVG src={ImgPlus} />Agregar Nuevo Item
      </button> 

      <BtnGeneral text={'Guardar'} img={Check} side={'L'} disabled={false} type="button" 
        action={Validar} clases={'primaryC'} large={true} />
      <BtnGeneral text={'Eliminar'} img={''} side={'L'} disabled={false} type="button" 
        action={Eliminar} clases={'redC'} large={true} />
    </div>
  );
}
 
export {
  ItemPrincipal
};