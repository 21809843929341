import { db } from '../../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

const AgregarDatos = async(props) => {
    
  const { datos } = props;

  const name = datos.iniDate.toString()

  return await setDoc(doc(db, 'informes', name),{
    ...datos
  })
}

export{
  AgregarDatos
}
