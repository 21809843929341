import React from 'react'
import { Helmet } from 'react-helmet-async';
import Testimonios from '../testimonios/Testimonios';
import Cuentas from '../cuentas/Cuentas';
import InformesInicio from '../informes/InformesInicio';
import ConfigAuto from '../rates/components/ConfigAuto';
import Coins from '../rates/components/Coins';
import TradicionalFree from '../rates/components/TradicionalFree';
import ConfigGeneralFee from '../fees/ConfigGeneralFee';
// import ConfigUpdate from '../configs/ConfigUpdate';

const InicioAdmin = () => {
  
  // const Activar = async() =>{await axios.post('http://localhost:3030/api/activatenew')}

  return (
    <div className='dashPage'>
      <Helmet><title>Dashboard Admin</title></Helmet>
      {/* <BtnGeneral text='Activar' img={''} side={'L'} disabled={false} type="button" action={Activar} clases={''} large={false} /> */}
      <InformesInicio />
      <Coins />
      <div className='card'>
        <p className='cardTitle'>Configuración General</p>
        <ConfigAuto />
        <TradicionalFree />
      </div>
      <ConfigGeneralFee />
      <Testimonios />
      <Cuentas />
    </div>
  )
}

export default InicioAdmin