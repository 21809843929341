import React, { useEffect, useState } from 'react';
import { useAllReports } from '../../../hooks/useReports';
import Chart from "react-apexcharts";
import { LoadingCard } from '../../../../components/loading/Loadings';
 
const Ventas = () => {

  const {results } = useAllReports();
  const [movimientos, setMovimientos] = useState();
  const [fee, setFees] = useState();
  const [usuarios, setUsuarios] = useState();
  const [number, setNumber] = useState();

  useEffect(() => {
    if(results){
      let sales = results.filter(z => z.year === '2024').map(a => Math.trunc(a.sales))
      let buys = results.filter(z => z.year === '2024').map(a => Math.trunc(a.buys))
      let fees = results.filter(z => z.year === '2024').map(a =>  Math.trunc(a.fees))
      let meses = results.filter(z => z.year === '2024').map(a => a.mes.substring(0, 3).toUpperCase())
      let users2024 = results.filter(z => z.year === '2024').map(a => a.users)
      let users2023 = results.filter(z => z.year === '2023').map(a => a.users)
      let users2022 = results.filter(z => z.year === '2022').map(a => a.users)
      let numberSales = results.filter(z => z.year === '2024').map(a => Math.trunc(a.salesNumber))
      let numberBuys = results.filter(z => z.year === '2024').map(a => Math.trunc(a.buysNumber))

      setMovimientos({
        options: {
          xaxis: {
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: "Venta",
            data: sales
          },
          {
            name: "Compra",
            data: buys
          }
        ]
      })

      setFees({
        options: {
          xaxis:{
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: 'Fee',
            data:  fees
          }
        ],
      })

      setUsuarios({
        options: {
          xaxis:{
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: '2024',
            data:  users2024
          },
          {
            name: '2023',
            data:  users2023
          },
          {
            name: '2022',
            data:  users2022
          },
        ],
      })

      setNumber({
        options: {
          xaxis: {
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: "Venta",
            data: numberSales
          },
          {
            name: "Compra",
            data: numberBuys
          }
        ]
      })
    }
  }, [results]);

  return (
    <div className='cardFlex2'>
      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>Movimientos</p>
          {movimientos ? 
            <Chart
              options={movimientos.options}
              series={movimientos.series}
              width='100%'
              type='area'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>Número de Transferencias</p>
          {number ? 
            <Chart
              options={number.options}
              series={number.series}
              width='100%'
              type='area'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>Servicio</p>
          {fee ?
            <Chart
              options={fee.options}
              series={fee.series}
              type='area'
              width='100%'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>Usuarios</p>
          {usuarios ?
            <Chart
              options={usuarios.options}
              series={usuarios.series}
              type='area'
              width='100%'
            />
            : <LoadingCard />
          }
        </div>
      </div>
    </div>
  );
}

export default Ventas;