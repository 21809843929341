import React, { useEffect, useState } from "react";
import { ModalValidar } from "../../../../components/modales/Modales";
import { EstadoServicio } from "../../../data/AdminRate";
import { useConfig } from "../../../../context/ConfigsContext";
import { HuboUnError, PopUpValid } from "../../../../components/mensajes/MensajesClient";
import Toggle from "../../../../components/inputs/Toggle";

const ServiceType = ({coinName, coinField, coinText, id}) =>{
    
	const {OpenModal, setProcesando} = useConfig();
	const [estado, setEstado] = useState();

	useEffect(() => {
		setEstado(coinField)
	}, [coinField]);
	
	const AbrirToggle = (e) =>{
		e.preventDefault();
		OpenModal(
			<ModalValidar 
			accion={ToggleStatus} 
			titulo={`Estado ${coinName}`}
			mensaje={`¿Deseas ${estado ? 'Desactivar' : 'Activar'} el método ${coinName}?`}
			botonName={`Sí,${estado ? ' Desactivar' : ' Activar'}`}
			botonClass={estado ? 'btnGeneral redC' : 'btnGeneral primaryC'}
		/>)
	}

	const ToggleStatus = () =>{
		setProcesando(true)
		const valor = estado ? false : true
		EstadoServicio({
			id: id,
			modo: coinText,
			status: valor
		}).then(async()=>{
			setProcesando(false)
			OpenModal(<PopUpValid mensaje={`El método ${coinName} ha sido ${valor ? 'Activado' : 'Desactivado'}`} />)
		}).catch(()=>{
			setProcesando(false)
			OpenModal(<HuboUnError />)
		})
	}

	return(
		<>
			<div className="itemConfig">
				<Toggle estado={estado} accion={AbrirToggle}/>
				<p>Método {coinName}</p>
			</div>
		</>
	)
}

export default ServiceType;