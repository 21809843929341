import React from 'react';
import { useConfig } from '../../../../context/ConfigsContext';
import Toggle from '../../../../components/inputs/Toggle';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { statusCoin } from '../../../data/AdminConfig';

import '../../../../styles/config.css'
 
const ConfigCoins = ({coin}) => {

  const {OpenModal, setProcesando} = useConfig();

  const ClickToggle = (status, name, id) =>{
    OpenModal(
      <ModalValidar 
        accion={()=>ToggleStatus(status, name, id)} 
        titulo={'Desactivar Moneda'}
        mensaje={`¿Deseas ${status ? 'Desactivar' : 'Activar'} el envío a ${name}?`}
        botonName={`Sí,${status ? ' Desactivar' : ' Activar'}`}
        botonClass={status ? 'btnGeneral redC' : 'btnGeneral primaryC'}
      />)
  }

  const ToggleStatus = (status, name, id) =>{
    setProcesando(true)
    const valor = status ? false : true
    statusCoin({
      status: valor,
      id: id
    }).then(async()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={`El envio a ${name} se ${!valor ? 'desactivó' : 'activó'}`} />)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError/>)
    })
  }

  return (
    <div className="itemConfig">
      <Toggle estado={coin.status} accion={()=>{ClickToggle(coin.status, coin.textTo, coin.id)}}/>
      <p>Enviar a {coin.textTo}</p>
    </div>
  );
}

export default ConfigCoins;