import React from 'react';
import { useTrans } from '../../../context/TransferContext';
import ServiceType from './components/ConfigFees'
import ConfigCoins from './../rates/components/ConfigCoins'
import SVG from 'react-inlinesvg';
import co from '../../../img/icons/co.svg';
import au from '../../../img/icons/au.svg';
import nz from '../../../img/icons/nz.svg';
 
const ConfigGeneralFee = () => {

  const {coins} = useTrans();

  return (
    <div className='card'>
      <div className='cardFlex3'>
      {coins && coins.sort((a, b) => a.order - b.order).map(coin =>{

        let image
        if(coin.to === 'COP'){image = co}
        if(coin.to === 'AUD'){image = au}
        if(coin.to === 'NZD'){image = nz}

        return (
          <div className='cardFlex3Div' key={coin.id}>
            <p className="cardTitle">{coin.textTo} <SVG src={image} /></p>
            <ConfigCoins coin={coin} />
            <ServiceType coinName={'Tradicional'} coinField={coin.tradicional} coinText={'tradicional'} id={coin.id}/>
            <ServiceType coinName={'UltraRápido'} coinField={coin.ultraRapido} coinText={'ultraRapido'} id={coin.id}/>
          </div>
          )
        })
      }
      </div>
    </div>
  );
}

export default ConfigGeneralFee;